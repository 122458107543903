.main-block{
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;
}
.list{
    list-style: disc;
    font-weight: 300;
    font-size: calc(var(--index) *1);
    width: 80%;
    margin-top: 24px;
    color: #000;
    margin: 0;
    text-align: left;
}
.main-block >h1{
    font-weight: 700;
    font-size: calc(var(--index) *4);
    color: #000;
    margin: 0;
    text-align: center;
    width: 60%;
}

.main-block >h2{
    font-weight: 300;
    font-size: calc(var(--index) *1);
    width: 80%;
    color: #000;
    margin: 0;
    text-align: center;
}

.main-block >h2 > p{
    font-weight: 300;
    font-size: calc(var(--index) *0.75);
    width: 100%;
    color: #000;
    margin: 0 auto;
    text-align: center;
    margin-top: 24px;
}

.massage-second-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: calc(80% - 80px);
    gap: 36px;
    margin-top: 48px;
}

.massage-second-block >h1{font-size: calc(var(--index) *2);}

.cards-m{display: flex; flex-direction: column; gap: 72px;width: 100%; justify-content: center; align-items: center; }



.massage-card > div:nth-child(1)
{
    align-items: center;
    justify-content: center;
    background-position: center;
    display: flex;
}

.massage-img-1,.massage-img-2,.massage-img-3,.massage-img-4,.massage-img-5,.massage-img-6
{
    width: 100%;
    height: 100%;
    background-position: center; background-size: cover; background-repeat: no-repeat;

}

.massage-img-1{background-image: url(../../image/HomePage/large_Depositphotos_.jpg);    height: 70%;} 
.massage-img-2{background-image: url(../../image/HomePage/large_Depositphotos_.jpg);height: 80%;} 
.massage-img-3{background-image: url(../../image/HomePage/large_Depositphotos_.jpg);height: 60%;}
.massage-img-4{background-image: url(../../image/HomePage/large_Depositphotos_.jpg);}
.massage-img-5{background-image: url(../../image/HomePage/large_Depositphotos_.jpg);}
.massage-img-6{background-image: url(../../image/HomePage/large_Depositphotos_.jpg); }


.mas-u{
    flex: 0.5;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: max-content;
}

.mas-u > h1{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--index) *1.5);
}

.mas-u > ul > li{list-style: disc;    font-size: calc(var(--index) *1); font-weight: 400;}

.mas-u > a {
    display: flex;
    align-items: center;
    border-radius: 99px;
    padding: 12px 20px;
    background-color: #1f5bff;
    width: max-content;
    color: #fff;
    font-weight: 700;
}


.title-block-mas
{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    text-align: center;
}

.title-block-mas > h2{
    font-size: calc(var(--index) *0.8);
    text-align: center;
    font-weight: 400;
    margin-top: 16px;
}

.title-block-mas > h1{
    font-size: calc(var(--index) *2);
    text-align: center;
}

.review-slider > h1{
    font-size: calc(var(--index) *2);
    text-align: center;
}


@media screen and (max-width:800px)
{


    .massage-card{display: flex;flex-direction: column; align-items: start; gap: 16px; width: 100%;}

    .massage-img-1,.massage-img-2,.massage-img-3,.massage-img-4,.massage-img-5,.massage-img-6
    {
        width: 100%;
        height: 200px;
        background-position: center; background-size: cover; background-repeat: no-repeat;
    }

    .title-block-mas > h2{
        font-size: calc(var(--index) *1.7);
        text-align: center;
        font-weight: 400;
        margin-top: 16px;
    }
    
    .title-block-mas > h1{
        font-size: calc(var(--index) *3);
        text-align: center;
    }
    
    .review-slider > h1{
        font-size: calc(var(--index) *3);
        text-align: center;
    }

    .main-block >h1{
        font-weight: 700;
        font-size: calc(var(--index) *3);
        color: #000;
        margin: 0;
        text-align: center;
        width: 100%;
    }
    
    .main-block >h2{
        font-weight: 300;
        font-size: calc(var(--index) *1.7);
        width: 80%;
        color: #000;
        margin: 0;
        text-align: center;
    }

    .mas-u{width: 100%;}
    .massage-btn{font-size: calc(var(--index) *1.5); margin: 0 auto;}
.massage-second-block >h1{font-size: calc(var(--index) *3); text-align: center; width: 60%}

.mas-u > ul > li{list-style: disc;    font-size: calc(var(--index) *1.5); font-weight: 400;}

.mas-u > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--index)* 2);
}

.massage-second-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    gap: 36px;
    margin-top: 48px;
}
}



.massage-page > .main-block > ul, .massage-page > .main-block > ul > li, .main-block > ul, .main-block > ul > li{
    list-style: disc;
    font-weight: 300;
    font-size: calc(var(--index) *1);
    width: 80%;
    color: #000;
    margin: 0;
    text-align: left;
}

.main-block>.anti{
    font-weight: 300;
    font-size: calc(var(--index) *1);
    color: #000;
    margin: 0;
    text-align: left;
    width: 80%;

}

.title
{
    font-weight:  700;
    font-size: calc(var(--index)* 1);
    color: #000;
    margin: 0;
    text-align: left;
    width: 80%;
}

.massage-card{display: flex;flex-direction: row; align-items: start; gap: 16px; width: 100%;min-height: 350px; height: auto;}

.sv > p {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    color: #1f5bff;
    border-radius: 99px;
    border: 1px solid #1f5bff;
}

.sss {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    margin-top: 36px;
    width: 100%;
}

.img-sl{
    max-width: 800px;
    max-height: 400px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto;
}

.review, .review-slider, .slick-list, .slick-track, .slick-slide
{
    height: auto;
}

.img-sl1{
    max-width: 800px;
    max-height: 400px;
    min-width: 500px;
    min-height: 400px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto;
}

.img-31
{
    background-image: url(../../image/spa-couple.jpg);
}

.img-32
{
    background-image: url(../../image/HomePage/massage-dlya-muzhchi.jpg);
}

.img-33
{
    background-image: url(../../image/two.jpg);
}

.tat-1{    background-image: url(../../image/tattoo-nadpis.jpg); background-size: cover; background-position: center;}
.tat-2{    background-image: url(../../image/tattoo-zapyastie.jpg); background-size: cover; background-position: center;}
.tat-3{    background-image: url(../../image/tattoo-sheya1.jpg); background-size: cover; background-position: center;}
.tat-4{    background-image: url(../../image/tattoo-ruka.jpg); background-size: cover; background-position: center;}
.tat-5{    background-image: url(../../image/tattoo-plecho.jpg);  background-position: center; background-size: contain;}
.tat-6{    background-image: url(../../image/tattoo-ruka5.jpg); background-size: contain; background-position: center;}
.tat-7{    background-image: url(../../image/tattoo-noga2.jpg); background-size: contain; background-position: center;}
.tat-8{    background-image: url(../../image/tattoo-spina.jpg); background-size: cover; background-position: center;}
.tat-9{    background-image: url(../../image/tattoo-grud0.jpg); background-size: cover; background-position: center;}
.tat-10{    background-image: url(../../image/tattoo-perekr.jpg); background-size: cover; background-position: center;}
.tat-11{    background-image: url(../../image/tattoo-rabota1.jpg); background-size: cover; background-position: center;}
.tat-12{    background-image: url(../../image/tattoo-rabota2.jpg); background-size: cover; background-position: center;}


.massage-img-1{background-image: url(../../image/Kosmetolog1.jpg); background-size: cover; background-position: center;}
.massage-img-2{background-image: url(../../image/massage-zhivot4.jpg); background-size: cover; background-position: center;}
.massage-img-3{background-image: url(../../image/HomePage/epilaciya.jpg); background-size: cover; background-position: center; height: 100%;}

@media screen and (max-width: 800px) {
    .massage-card
    {
        flex-direction: column;
    }
    .sewas{height: 400px;}
.sewas{height: 400px;}
.sewas{height: 400px;}
    
}

.main-block-spa
{
    display: flex;
    flex-direction: column;
    margin: 40px 40px;
    align-items: center;
    gap: 72px;
}

.main-block-spa > h1
{
    font-weight: 700;
    font-size: calc(var(--index) *4);
    color: #000;
    margin: 0;
    text-align: center;
    width: 80%;
}

.thr-col{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 36px;
}

.fr-col,.sc-col,.th-col{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 36px;
    text-align: center;
}

.col-icon-1{
    display: block;
    width: calc(var(--index) *4);
    height: calc(var(--index) *4);
    background-image: url("../../image/HomePage/1.svg");
}
.col-icon-2{
    display: block;
    width: calc(var(--index) *4);
    height: calc(var(--index) *4);
    background-image: url("../../image/HomePage/2.svg");
}
.col-icon-3{
    display: block;
    width: calc(var(--index) *4);
    height: calc(var(--index) *4);
    background-image: url("../../image/HomePage/3.svg");
}

.fr-col p,.sc-col p,.th-col p
{
    font-size: 16px;
    line-height: 1.55;
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: 300;
    color: #000;

}

@media screen and (max-width: 800px){
    .thr-col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 36px;
    }
}


.card__img-search-210
{
    background-image: url("../../image/HomePage/massage-face1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-211
{
    background-image: url("../../image/two.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-212
{
    background-image: url("../../image/HomePage/massage-spa2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-213
{
    background-image: url("../../image/massage-detskiy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-214
{
    background-image: url("../../image/vakuumno-rolikovyy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-800
{
    background-image: url("../../image/HomePage/massage-face1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-801
{
    background-image: url("../../image/two.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-802
{
    background-image: url("../../image/HomePage/massage-spa2.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-803
{
    background-image: url("../../image/HomePage/massage-detskiy.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-804
{
    background-image: url("../../image/vakuumno-rolikovyy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-805
{

    background-image: url("../../image/HomePage/massage-zhivot4.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-806
{
    background-image: url("../../image/HomePage/massage-face1.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-555
{
    background-image: url("../../image/HomePage/flaer.jpeg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-556
{
    background-image: url("../../image/HomePage/flaer.jpeg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-557
{
    background-image: url("../../image/HomePage/flaer.jpeg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-900
{
    background-image: url("../../image/chistka-litsa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-901
{
    background-image: url("../../image/massage-zhivot4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-902
{
    background-image: url("../../image/HomePage/epilaciya.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-154
{
    background-image: url("../../image/spaschastie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-155
{
    background-image: url("../../image/yaponskiy-massage-li.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-156
{
    background-image: url("../../image/HomePage/spa-programs2.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-157
{
    background-image: url("../../image/massage-more3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-158
{
    background-image: url("../../image/massage-bambuk.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-159
{
    background-image: url("../../image/goa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-160
{
    background-image: url("../../image/massage-ispanskiy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-161
{
    background-image: url("../../image/spaenergy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-162
{
    background-image: url("../../image/spameditaciya.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-163
{
    background-image: url("../../image/spathayskaya.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-164
{
    background-image: url("../../image/HomePage/spatropicheskaya.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-165
{
    background-image: url("../../image/HomePage/spachokolate.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-166
{
    background-image: url("../../image/spamonarh.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-167
{
    background-image: url("../../image/spaglintveyn.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-168
{
    background-image: url("../../image/HomePage/sparehabilitation.jpg\ ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-169
{

    background-image: url("../../image/HomePage/spasovershenstvo.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-170
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-171
{
    background-image: url("../../image/HomePage/spapodpalmoy.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-172
{
    background-image: url("../../image/spa-dlya-dvoih2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-173
{
    background-image: url("../../image/massage-dlya-dvoih1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-174
{
    background-image: url("../../image/HomePage/2-backpackerinnen-4-.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-175
{
    background-image: url("../../image/spaletnee.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-176
{
    background-image: url("../../image/cocos.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-177
{
    background-image: url("../../image/goa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-178
{
    background-image: url("../../image/relax.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-179
{
    background-image: url("../../image/slimming.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-180
{
    background-image: url("../../image/citrus.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-181
{
    background-image: url("../../image/HomePage/tibet.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-182
{
    background-image: url("../../image/maldivy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-183
{
    background-image: url("../../image/kosta-rica.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-184
{
    background-image: url("../../image/atlantida.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-185
{background-image: url("../../image/sibir.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-186
{
    background-image: url("../../image/HomePage/7chudes.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-187
{background-image: url("../../image/zhemchuzhina.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-188
{
    background-image: url("../../image/okean.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-189
{
    background-image: url("../../image/spaschastie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-190
{
    background-image: url("../../image/yaponskiy-massage-li.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-191
{
    background-image: url("../../image/HomePage/spa-programs2.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-192
{
    background-image: url("../../image/massage-more3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-193
{
    background-image: url("../../image/massage-bambuk.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-194
{
    background-image: url("../../image/goa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-195
{
    background-image: url("../../image/massage-ispanskiy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-196
{
    background-image: url("../../image/spaenergy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-197
{
    background-image: url("../../image/spameditaciya.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-198
{
    background-image: url("../../image/spathayskaya.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-199
{
    background-image: url("../../image/HomePage/spatropicheskaya.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-200
{
    background-image: url("../../image/HomePage/spachokolate.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-201
{
    background-image: url("../../image/spamonarh.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-202
{
    background-image: url("../../image/spaglintveyn.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-203
{
    background-image: url("../../image/HomePage/sparehabilitation.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-204
{
    background-image: url("../../image/HomePage/spasovershenstvo.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-205
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-206
{
    background-image: url("../../image/HomePage/spapodpalmoy.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-207
{
    background-image: url("../../image/spa-dlya-dvoih2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-208
{
    background-image: url("../../image/massage-dlya-dvoih1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-209
{
    background-image: url("../../image/HomePage/2-backpackerinnen-4-.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-210
{
    background-image: url("../../image/HomePage/massage-face1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-211
{
    background-image: url("../../image/two.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-212
{
    background-image: url("../../image/HomePage/massage-spa2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}


.card__img-search-213
{
    background-image: url("../../image/HomePage/massage-detskiy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-214
{
    background-image: url("../../image/HomePage/vakuumno-rolikovyy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-215
{
    background-image: url("../../image/HomePage/massage-zhivot4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-216
{
    background-image: url("../../image/HomePage/tibet.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-217
{
background-image: url("../../image/maldivy.jpg");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
}


.card__img-search-218
{
    background-image: url("../../image/kosta-rica.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-219
{
    background-image: url("../../image/atlantida.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-220
{
    background-image: url("../../image/sibir.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-221
{
    background-image: url("../../image/HomePage/7chudes.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-222
{
    background-image: url("../../image/zhemchuzhina.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-223
{
    background-image: url("../../image/okean.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-900
{
    background-image: url("../../image/chistka-litsa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-901
{
    background-image: url("../../image/massage-zhivot4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-902
{
    background-image: url("../../image/HomePage/epilaciya.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-903
{
    background-image: url("../../image/HomePage/tattoo-nadpis.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-904
{
    background-image: url("../../image/tattoo-zapyastie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-905
{
    background-image: url("../../image/tattoo-sheya1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-906
{
    background-image: url("../../image/tattoo-ruka.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-907
{
    background-image: url("../../image/tattoo-plecho.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-908
{
    background-image: url("../../image/tattoo-ruka5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-909
{
    background-image: url("../../image/tattoo-noga2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-910
{
    background-image: url("../../image/tattoo-spina.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-911
{
    background-image: url("../../image/tattoo-grud0.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-912
{
    background-image: url("../../image/tattoo-perekr.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-913
{
    background-image: url("../../image/tattoo-rabota1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-914
{
    background-image: url("../../image/tattoo-rabota2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-915
{
    background-image: url("../../image/massage-dlya-dvoih1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.obuc-cos916
{
    background-image: url("../../image/chistka-litsa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-917
{
    background-image: url("../../image/tattoo-rabota2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.img-918
{
    background-image: url("../../image/spa-couple.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.img-919
{
    background-image: url("../../image/HomePage/massage-dlya-muzhchi.jpg ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.img-920
{
    background-image: url("../../image/HomePage/two.png ");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-111
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-112
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-113
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-114
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-115
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-116
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-117
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-118
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-119
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-120
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-121
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-122
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-123
{ background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-124
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-125
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-126
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-127
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-128
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-129
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-130
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-131
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-132
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-133
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-134
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-135
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-136
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-137
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-13
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-139
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-140
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-141
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-142
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-143
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-144
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-145
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-146
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-147
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-148
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-149
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-150
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-151
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-152
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.card__img-search-153
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-154
{
    background-image: url("../../image/HomePage/massage-face1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.card__img-search-155
{
    background-image: url("../../image/yaponskiy-massage-li.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
{
    background-image: url("../../image/yaponskiy-massage-li.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-156
{
    background-image: url("../../image/HomePage/spa-programs2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-157
{
    background-image: url("../../image/massage-more3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-158
{
    background-image: url("../../image/massage-bambuk.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-159
{
    background-image: url("../../image/goa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-160
{
    background-image: url("../../image/massage-ispanskiy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-161
{
    background-image: url("../../image/spaenergy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-162
{
    background-image: url("../../image/spameditaciya.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-163
{
    background-image: url("../../image/spathayskaya.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-163
{
    background-image: url("../../image/spathayskaya.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-165
{
    background-image: url("../../image/HomePage/spachokolate.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-166
{
    background-image: url("../../image/spamonarh.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-167
{
    background-image: url("../../image/spaglintveyn.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-168
{
    background-image: url("../../image/HomePage/sparehabilitation.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-169
{
    background-image: url("../../image/HomePage/spasovershenstvo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-170
{
    background-image: url("../../image/spadyhanie.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-171
{
    background-image: url("../../image/HomePage/spapodpalmoy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-172
{
    background-image: url("../../image/spa-dlya-dvoih2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-173
{
    background-image: url("../../image/massage-dlya-dvoih1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-174
{
    background-image: url("../../image/HomePage/2-backpackerinnen-4-.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-175
{
    background-image: url("../../image/spaletnee.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-176
{
    background-image: url("../../image/cocos.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-177
{
    background-image: url("../../image/goa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-178
{
    background-image: url("../../image/relax.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-179
{
    background-image: url("../../image/slimming.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-180
{
    background-image: url("../../image/citrus.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-181
{
    background-image: url("../../image/HomePage/tibet.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-182
{
    background-image: url("../../image/maldivy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-183
{
    background-image: url("../../image/kosta-rica.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-184
{
    background-image: url("../../image/atlantida.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-185
{
    background-image: url("../../image/sibir.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-186
{
    background-image: url("../../image/HomePage/7chudes.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-187
{
    background-image: url("../../image/zhemchuzhina.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.card__img-search-188
{
    background-image: url("../../image/okean.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
