.img-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 26px;
    height: calc(100vh - 90px);
    gap: 36px;
    background-image: url(../../image/HomePage/large_Depositphotos_\ 1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.img-main > h1{
    font-weight: 700;
    font-size: calc(var(--index) *4);
    color: #fff;
    margin: 0;
    text-align: center;
    width: 60%;
}

.img-main > h2{
    font-weight: 300;
    font-size: calc(var(--index) *1.2);
    width: 60%;
    color: #fff;
    margin: 0;
    text-align: center;
}

.btn-block
{
    padding: 20px 110px;
    font-size: 36px;
    min-height: 160px;
    background-color: #f08316;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.middle-block{
    margin: 0 auto;
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 36px;
    width: 60%;
}
.hg{display: flex;flex-direction: row;align-items: center; gap: 36px; height: 100%;}
.hg > div{display: flex;flex-direction: column; gap: 16px; height: 100%;}
.hg > div> h5 {font-size: calc(var(--index) *0.9); font-weight: bold;}
.hg > div> h6 {font-size: calc(var(--index) *0.8); font-weight: 400;}
.svg
{
    width: calc(var(--index) *4);
    height: calc(var(--index) *4);
}

.svgg
{
    width: calc(var(--index) *3.3);
    height: calc(var(--index) *4);
}

.second-block
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 36px;
    margin: 0 40px;
    margin-top: 48px;
}

.second-block>h1
{
    font-size: calc(var(--index) *2);
    display: block;
    margin: 0 auto;
    font-weight: bold;
}
.second-block>p
{
    font-weight: 300;
    font-size: calc(var(--index) *0.9);
    width: 90%;
    font-weight: 600;
}

.second-block>p>a{
    color: #ff8562;
    font-weight: 700;
}

.thride-block
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 40px;
    margin-top: 48px;
}


.thride-block__cards{
    display: flex;
    flex-wrap: wrap; /* Важно для переноса на новую строку */
    justify-content: space-between; /* Равномерное распределение по строкам */
    gap: 24px; 
    margin-top: 36px;
    width: 80%;
}


.thride-block__card{
    display: flex;
    flex-direction: column;
    width: calc(50% - 12px);
    height: max-content;
    align-items: start;
}

.card__img-1
{
    width: 100%;
    height: calc(var(--index)*15);
    background-image: url(../../image/HomePage/microneedle.jpg);
    background-size:cover;
    background-repeat: no-repeat;
}
.card__img-2
{
    width: 100%;
    height: calc(var(--index)*15);
    background-image: url(../../image/HomePage/shutterstock_1222085.jpg);
    background-size:cover;
    background-repeat: no-repeat;
}
.card__img-3
{
    width: 100%;
    height: calc(var(--index)*15);
    background-image: url("../../image/e.jpg");
    background-size:cover;
    background-repeat: no-repeat;
}
.card__img-4
{
    width: 100%;
    height: calc(var(--index)*15);
    background-image: url(../../image/HomePage/YdbnUzZvFrA.jpg);
    background-size:cover;
    background-repeat: no-repeat;
}
.card__img-5
{
    width: 100%;
    height: calc(var(--index)*15);
    background-image: url(../../image/HomePage/обучениеКосм5.jpeg);
    background-size:cover;
    background-repeat: no-repeat;
}

.card__title
{
    margin-top: 16px;
    font-size: calc(var(--index) *1);
}

.card__subtitle
{
    font-size: calc(var(--index) *0.7);
    font-weight: 400;
    margin-top: 4px;
}

.card__btn
{
    margin-top: 16px;
    display: block;
    width: max-content;
    padding: 8px 24px;
    border: #1F1E1E 1px solid;
    border-radius: 7px;
    transition: all .3s ease;
}


.card__btn:hover{
    background-color: #1F1E1E;
    color: #fff;
}

.center{
    display: block;
    margin: 0 auto;
}

.uslugi{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 48px 40px;
}

.uslugi-block__cards {
    display: flex;
    flex-wrap: wrap; /* Разрешаем перенос на новую строку */
    justify-content: space-between; /* Равномерно распределяем по строкам */
    gap: 24px; /* Промежуток между карточками */
    flex-direction: column;
    width: 100%;
  }
  
  .uslugi-block__card {
    width: calc(33.33% - 16px); /* Ширина 33.33% с учетом промежутка */
    height: max-content;
    align-items: start;
  }
  
  .uslugi-block__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


.uslugi-card__img-1
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url(../../image/HomePage/обучениеКосм5.jpeg);
    background-size:cover;
    background-repeat: no-repeat;
}
.uslugi-card__img-2
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url(../../image/HomePage/YdbnUzZvFrA.jpg);
    background-size:cover;
    background-repeat: no-repeat;
}
.uslugi-card__img-3
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url("../../image/massage-spa222222.jpg");
    background-size:cover;
    background-repeat: no-repeat;
}
.uslugi-card__img-4
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url("../../image/chistka-litsa.jpg");
    background-size:cover;
    background-repeat: no-repeat;
}
.uslugi-card__img-5
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url("../../image/tattoo-rukav0.jpg");
    background-size:cover;
    background-repeat: no-repeat;
}
.uslugi-card__img-6
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url("../../image/kosmeticheskiy-massa.jpg");
    background-size:cover;
    background-repeat: no-repeat;
}
.uslugi-card__img-9
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url(../../image/HomePage/logonew.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
}
.uslugi-card__img-7
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url(../../image/HomePage/massage-indiyskiy.jpg);
    background-size:cover;
    background-repeat: no-repeat;
}

.uslugi-card__img-8
{
    width: 100%;
    height: calc(var(--index)*10);
    background-image: url("../../image/massage-dlya-dvoih4.jpg");
    background-size:cover;
    background-repeat: no-repeat;
}


.review-slider {
    text-align: center;
    margin: 48px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.review {
    margin-top: 24px;
    padding: 20px 0px;
    padding-left: 20px;
    background: #fff;
    border-radius: 10px;
    border: none;
    outline: none;
    display: flex;
    align-items: start;
    justify-content: flex-start;
    text-align: left;
}

.slick-slider{
    width: 70%;

}

.review p {
    margin: 10px 0;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 24px;
    z-index: 2;
    color: #000;
}

.slick-arrow::before {
    display: none;
}

.slick-prev {
    left: -25px;
}

.slick-next {
    right: -25px;
}

.slick-dots {
    bottom: -30px;
}

/* Ensure only one set of arrows is displayed */
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    display: none !important;
}

.slick-arrow:hover{color: #000;  border-color: #000;}


.fo-block
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 48px 40px;
}

.fo-block > h1{
    font-size: calc(var(--index) *2);
    font-weight: bold;
}

.fo-block > h2{
    font-size: calc(var(--index) *1);
    font-weight: 400;
    margin-top: 8px;
}

.spec{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 16px;
    justify-content: center;
}

.spec-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 260px;
}

.spec-img-1
{
    width: 260px;
    height: 260px;
    background-image: url(../../image/HomePage/fir.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.spec-img-2
{
    width: 260px;
    height: 260px;
    background-image: url(../../image/HomePage/two.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.spec-img-3
{
    width: 260px;
    height: 260px;
    background-image: url(../../image/HomePage/thrid.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.spec-img-4
{
    width: 260px;
    height: 260px;
    background-image: url(../../image/HomePage/fo.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



@media screen and (max-width:800px)
{
    .fo-block > h1{
        font-size: calc(var(--index) *3);
        font-weight: bold;
    }
    
    .fo-block > h2{
        font-size: calc(var(--index) *2);
        font-weight: 400;
        margin-top: 8px;
        text-align: center;
    }

    .uslugi-block__row
    {
        flex-direction: column;
        gap: 24px;
    }

    .uslugi-block__card
    {
        width: 100%;
        
    }

    .card__title{
        font-size: calc(var(--index) *2);
    }

    .svg, .svg > path {
        width: 120px;
    }
    
    .sv{width: 70px; display: flex; justify-content: center; align-items: center;}

    .middle-block{
        width: 80%;
        margin: 0 40px;
        margin-top: 72px;
    }

    .hg {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 100%;
    }
    .svgg {
        width: 120px;
    }

    .review-slider {
        text-align: center;
        margin: 48px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .slick-slider {
        width: 85%;
        /* height: 50%; */
        /* margin: 40px; */
    }

    .hg > div> h5 {
        font-size: calc(var(--index)* 1.4);
        font-weight: bold;
    }

    .hg > div> h6 {
        font-size: calc(var(--index)* 1.1);
    }

    .hg > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 100%;
    }

    .uslugi-card__img-1,.uslugi-card__img-2,.uslugi-card__img-3,.uslugi-card__img-4,.uslugi-card__img-5,.uslugi-card__img-6,.uslugi-card__img-7,.uslugi-card__img-8,.uslugi-card__img-9{
        height: calc(var(--index)* 20);
    }
}

@media screen and (max-width:400px)
{

    .svg, .svg > path {
        width: 120px;
    }
    
    .sv{width: 70px; display: flex; justify-content: center; align-items: center;}

    .middle-block{
        width: 80%;
        margin: 0 40px;
        margin-top: 72px;
    }

    .hg {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        height: 100%;
    }
    .svgg {
        width: 120px;
    }

    .hg > div> h5 {
        font-size: calc(var(--index)* 1.4);
        font-weight: bold;
        text-align: center;
    }

    .hg > div> h6 {
        font-size: calc(var(--index)* 1.1);
        text-align: center;
    }

    .hg > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 100%;
    }
}




@media screen and (max-width:600px)
{
    .thride-block__cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;
        margin-top: 36px;
        width: 100%;
    }

    .thride-block__card {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: max-content;
        align-items: start;
    }

    .thride-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
        margin: 0 40px;
        margin-top: 48px;
    }

    .thride-block > h2 {
        margin-top: 8px;
        font-size: calc(var(--index) *1.5);
        font-weight: 400;
    }

    .card__subtitle {
        font-size: calc(var(--index)* 1);
        font-weight: 400;
        margin-top: 4px;
    }

    .second-block>p {
        font-weight: 300;
        font-size: calc(var(--index)* 1);
        width: 90%;
        font-weight: 600;
    }

    .second-block {
        gap: 16px;
    }

    .img-main > h1 {
        font-weight: 700;
        font-size: calc(var(--index)* 3);
        color: #fff;
        margin: 0;
        text-align: center;
        width: 60%;
    }

    .region{
        width: 80px;
    }

    .number{
        font-size: calc(var(--index) *1.4);
    }
}