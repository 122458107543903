


















.header {
    width: 100%;
    color: #4C4B49;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}


.header > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header div nav:nth-child(1) {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header div nav:nth-child(1) > div,
.header div nav:nth-child(1) > a {
    color: #4C4B49;
    width: max-content;
}

.header div nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0 40px;

}

.nav-left,
.nav-right {
    display: flex;
    list-style: none;
    flex: 1.8;
    justify-content: end;
}

.nav-left {
    align-items: center;
    justify-content: center;
}

.nav-left li,
.nav-right li {
    margin: 0 10px;
    width: max-content;
    position: relative;
}

.nav-left li a,
.nav-right li a {
    color: #4C4B49;
    opacity: 0.8;
    text-decoration: none;
    font-weight: bold;
    font-size: calc(var(--index) * 0.8);
}

.logo {
    z-index: 10;
    flex: 0.2;
    width: calc(var(--index) *2);
    height: 50px;
    background-image: url(../../image/HomePage/logonew.jpg); 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.nav-left li a:hover,
.nav-right li a:hover {
    color: #252524;
    opacity: 1;
    text-decoration: none;
}

.search-form {
    display: flex;
    align-items: center;
    flex: 0.5;
    justify-content: end;
}

.search-input {
    padding: 5px;
    width: 50%;
    font-size: 16px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #4C4B49;
    background-color: transparent;
    outline: none
}

.search-button {
    padding: 3.5px 10px;
    font-size: 16px;
    background-color: transparent;
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    border: 1px solid #4C4B49;
    cursor: pointer;
}

.search-button:hover {
    background-color: #555;
}

@media screen and (max-width: 800px) {
    .nav-left,
    .nav-right {
        display: none;
    }
    .nav-left li,
    .nav-right li {
        display: none;
    }
    .nav-left li a,
    .nav-right li a {
        display: none;
    }
    header {
        width: 100%;
        color: #fff;
        height: calc(var(--index) * 4);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.header  *{list-style: none;}
.burger-menu {

    display: none;
}

.burger-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 24px;
}

.burger-icon div {
    width: 25px;
    height: 2px;
    background-color: #000;
    transition: all 0.3s;
}

.burger-icon.open .line1 {
    transform: rotate(45deg) translate(5px, 5px);
}

.burger-icon.open .line2 {
    opacity: 0;
}

.burger-icon.open .line3 {
    transform: rotate(-45deg) translate(5px, -5px);
}

.menu {
    display: none;
    position: fixed;
    top: 0;
    left: -40px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #000;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: -1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.logos {
    z-index: 3330000;
    display: block;
    position: absolute;
    top: 16px;
    width: calc(var(--index) *10);
    height: calc(var(--index) *5);
    background-color: #000;
    background-image: url(../../image/HomePage/logonew.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.menu.open {
    display: flex;
    opacity: 1;
    z-index: 1000;
    overflow-y: scroll
}

.menu ul {
    list-style: none;
    padding: 0;

}

.menu li {
    padding: 8px;
    font-size: 16px;
}

.menu li a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.sub-menu a{
    font-size: 10px;
}

.menu-list
{
    display: block;
    margin: 0 auto;
    position: absolute;
    top: calc(var(--index) *12);
}

.dropdown-content
{
    margin-left: 0;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 30px;
    color: #000;
    cursor: pointer;
}

@media (max-width: 800px) {
    .burger-menu {
        display: flex;
        width: auto;
        right: 0;
        justify-content: flex-end;
        align-items: end;
    }

    .header div nav
    {
        display: flex;
        justify-content: space-between;
    }

    .header > div
    {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .burger-icon
    {
        align-items: end;
    }

    .search-form{display: none;}

    .nav-left,
    .nav-right {
        display: none;

    }

    .dropdown-content {
        display: none;
        position: absolute;
        justify-content: center;
        background-color: white;
        min-width: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .dropdown-content a {
        color: black;
        padding: 12px 0px;
        text-decoration: none;
        display: block;
        text-align: center;
    }
    
    .dropdown-content a:hover {
        background-color: #ddd;
    }
    
    .dropdown:hover .dropdown-content {
        display: none;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
}



.dropdown-content {
    display: none;
    position: absolute;
    justify-content: center;
    background-color: white;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.dropdown-content a {
    color: black;
    padding: 12px 0px;
    text-decoration: none;
    display: block;
    text-align: center;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}










  
  .card__img-1, .card__img-2, .card__img-3 {
    width: 100%;
    background-color: #f0f0f0;
    margin: 0 auto 10px;
      height: calc(var(--index)* 15);
      flex: 0.5;
  }
  
  .card__title {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .card__subtitle {
    font-size: 1em;
    color: #666;
  }
  
  .card__duration {
    font-size: 0.9em;
    color: #999;
  }
  
  .card__btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    margin-top: 10px;
  }
  
  .card__btn:hover {
    background-color: #0056b3;
  }
  
  .search-form {
    display: flex;
    justify-content: end;      flex: 0.2 1;
  }
  
  .search-input {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;

  }

  @media screen and (max-width: 800px) {
      .search-form {
          display: flex;
          justify-content: end;
          flex: 1;
      }


      .logo
      {
          flex: 0.4;
      }
  }

  .search-inputs
  {
      display: flex;
      align-items: end;
      justify-content: end;

  }
  
  .search-button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
















  
  .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 1000;
    display: none; 
  }
  
  .search-overlay__content {
    background: white;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    max-height: 90%;
    overflow-y: auto;
  }
  
  .close-button {
    background: #ff0000;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .filter-section {
    flex: 1;
    padding: 20px;
    background: #f9f9f9;
    border-right: 1px solid #ddd;
  }
  
  .filter-section h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .filter-section label {
    display: block;
    margin-bottom: 10px;
  }
  
  .filter-section input[type="text"] {
    width: calc(50% - 10px);
    padding: 5px;
    margin-right: 10px;
  }

  
  .search-results {
    flex: 3;
    padding: 20px;
  }
  

  
  /* CSS стили */


.search-overlay.open {
  display: flex;
}

.filter-section {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100vw;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.filter-section h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.filter-section label {
  display: flex;
  margin-bottom: 10px;
  gap: 8px;
}

.filter-section input[type="text"] {
  width: calc(100% - 10px);
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.filter-section .prim, .filter-section .ochist {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.filter-section .ochist {
  background-color: #6c757d;
}

.search-results {
    display: flex;
    flex-wrap: wrap;
    flex: 3;
    padding: 20px;
    justify-content: space-between;
    gap: 24px;
}

.btns-fil{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}



.search-result__item{width: 100%; max-height: 250px; height: 100%; max-width: 450px;}
.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 14px;
}

.tag {
    background-color: transparent;
    border: 1px solid #1F1E1E;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all .2s ease;
}

.tag:hover {
    background-color: #1F1E1E;
    border: 1px solid #1F1E1E;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: #fff;
}

.logo-sect
{
    display: block;
    width: 100%;
    height: 150px;
    background-image: url("./../../image/logo.jpg");
    background-position: center;
    background-size: cover;
    position: relative;
}


.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevents body scroll */
}

.search-overlay.open {
    display: flex;
}

.filter-section {
    background-color: #fff;
    padding: 20px;
    width: 80%;
    height: 100vh;
    max-height: 100vh; /* Ensures the overlay content doesn't exceed 80% of the viewport height */
    overflow: auto;
    overflow-x: hidden; /* Enables scrolling within the filter section */
    border-radius: 10px;
}

.search-results {
    margin-top: 20px;
    background-color: #fff;
    width: 80%;
    height: 100vh;
    overflow: auto;
    padding: 10px;
    justify-content: space-around;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #1F1E1E;
    cursor: pointer;
    z-index: 100000;
}

.search-result__item
{
    min-height: 200px;
    height: auto;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 45%;
}
@media screen and (max-width: 800px){
    .search-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        overflow: hidden; /* Prevents body scroll */
    }

    .search-overlay.open {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 0;
        margin: 0;
        padding: 0;

    }

    .filter-section {
        background-color: #fff;
        width: calc(100% - 40px);
        max-width: 100%;
        max-height: 100vh; /* Ensures the overlay content doesn't exceed 80% of the viewport height */
        overflow: auto;
        overflow-x: hidden; /* Enables scrolling within the filter section */
        border-radius: 0px;
        flex: 0.7;
        box-shadow: none;
    }



    .search-results {
        border-top: 1px solid #1F1E1E;
        background-color: #fff;
        width: calc(100% - 40px);
        height: 100%;
        overflow: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .filter-section .kkk
    {
        display: none;
    }



    .logo-sect
    {
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .search-result__item
    {
        min-height: 200px;
        height: auto;
        max-height: 400px;
        margin-top: 24px;
        max-width: 450px;
    }

    .search-result__item > div{display: flex;flex-direction: column;  gap: 4px}
    .search-result__item > div>div{display: flex;flex-direction: column; gap: 4px}
}



.search-result__item  button, .cart-item button{
    display: block;
    background-color: transparent;
    outline: none;
    border: 1px solid #1F1E1E;
    padding: 8px 16px;
    border-radius: 99px;
    cursor: pointer;
    transition: all .2s ease;
    width: max-content;
    height: max-content;
}
.search-result__item  button:hover{
    display: block;
    background-color: #000;
    color: #fff;
    outline: none;
    border: 1px solid #1F1E1E;
    padding: 8px 16px;
    border-radius: 99px;
}

.search-result__item > div{display: flex;flex-direction: row; justify-content: space-between; align-items: center}
.search-result__item > div>div, .item-info{display: flex;flex-direction: column; gap: 4px; flex: 2; list-style: none}


@media screen and (max-width: 500px) {

}

.search-inputs{
    display: block;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-image: url("../../image/search.png");
    max-width: 100px;
    border-radius: 5px;
    padding: 4px;
    height: 20px;
    /*margin-right: 8px;*/
    cursor: pointer;
}

.forms{display: flex; flex-direction: row; gap: 16px;}

.search-form {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.cart-icon
{
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../image/cart.png");
    width: 20px;
    height: 20px;
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
}









    /* фото в поиске */

.card__img-search-1{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-2{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-3{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-4{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-5{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-6{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-7{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-8{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-9{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}

.card__img-search-10{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../image/HomePage/1612394780_73-p-kori.jpeg");
    width: 100%;
    height: calc(var(--index) *12);
}.notification {
     position: fixed;
     background-color: #4caf50;
        width: 300px;
     color: white;
     padding: 15px;
     border-radius: 5px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     z-index: 33000;
     animation: fadein 0.5s, fadeout 0.5s 2.5s;
 }

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}


.class-icon-wrap
{
    position: fixed;
    top: 150px;
    right: 36px;
    background-color: #ffffff70;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
}

.cart-icon
{
    background-size: cover;
    width: 60%;
    height: 60%;
}

.header-icons_filters{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header-icons_filters img
{
    width: 30px;
    height: 30px;
}


@media screen and (max-width: 500px){
    .header-icons_filters img {
        width: 20px;
        height: 20px;
    }

    .search-form {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}


@media screen and (max-width: 340px){
    .forms{
        flex-direction: column;
    }

}