.cart-page {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 40px;
    gap: 24px;
}

.cart-page > h2 {
    font-size: calc(var(--index) * 1.5);
    font-weight: 700;
}

.main-container__cart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    max-height: 3000px;
    height: auto;
}

.lsc {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
}

.first-card, .second-card, .oplata-card {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    border-radius: calc(var(--index) * 0.5);
    height: 100%;
    padding: 16px;
}

.first-card > h2 {
    margin-bottom: 16px;
}
.first-card{    justify-content: center;}
.second-card {
    gap: 8px;
    justify-content: center;
}

.second-card > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: calc(var(--index) * 0.8);
}

.oplata-card{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.oplata-card > div:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 5%;
}


.oplata-card > div:nth-child(2) > h3
{
    font-weight: 400;
    border: 1px solid #4C4B49;
    padding: 8px 12px;
    border-radius: 99px;
    font-size: calc(var(--index) * 0.6);
    transition: all .2s ease;
}
.oplata-card > div:nth-child(2) > h3:hover
{
    font-weight: 400;
    border: 1px solid #4C4B49;
    padding: 8px 12px;
    border-radius: 99px;
    font-size: calc(var(--index) * 0.6);
    background-color: #000;
    color: #fff;
}

.oplata-form{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.oplata-form > input
{
    border-radius: 20px;
    padding: 10px;
    border: 1px solid #d9d9d9;
}

.oplata-form > button{
    width: max-content;
    padding: 10px 16px;
    background-color: transparent;
    border: 1px solid #1F1E1E;
    border-radius: 99px;
    transition: all .2s ease;
    cursor: pointer;
}

.oplata-form > button:hover{
    width: max-content;
    padding: 10px 16px;
    background-color: #1f5bff;
    border: 1px solid #1f5bff;
    color: #fff;
    border-radius: 99px;
}

.chicki
{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.cart-item{list-style: none; display: flex; flex-direction: row; gap: 16px; width: 80%;}
.cart-item > div{display: flex;flex-direction: column;}
.cart-item > div:nth-child(1){ width: auto; height: auto; min-width: 200px; min-height: 100px;  max-width: 300px; max-height: 150px;}
.first-card ul{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.chicki p {font-size: calc(var(--index) *0.6)}
.chicki p a {color: #1f5bff}


@media screen and (max-width: 1050px) {
    .main-container__cart {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;
        max-height: 3000px;
        height: auto;
    }

    .oplata-form > input {
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #d9d9d9;
        max-width: 500px;
        min-width: 250px;
        margin: 0 20%;
    }
}

@media screen and (max-width: 600px) {

    .oplata-form > input {
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #d9d9d9;
        max-width: 500px;
        min-width: 0;
        margin: 0;
    }

    .cart-item {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 80%;
    }
    .cart-item > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .cart-page p, .cart-page strong, .cart-page h3 {
        font-size: 14px;
    }

    .oplata-card > div:nth-child(2) > h3{
        font-size: 8px;
    }
    .first-card ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin: 0;
        padding: 0;
    }

}

@media screen and (max-width: 350px){
    .oplata-card > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        gap: 8px;
        /* margin: 0 5%; */
    }

    .oplata-card > div:nth-child(2) > h3 {
        font-size: 8px;
        width: max-content;
    }
}

